import React from 'react';
import PropTypes from 'prop-types';

import ImageBlock from 'components/ImageBlock';
import Text from 'components/Text';
import Heading from 'components/Heading';

import classes from './TeamImage.scss';

function TeamImage({ name, image, position, children }) {
  return (
    <div className={classes.root}>
      <ImageBlock
        className={classes.block}
        overlay={false}
        title={name}
        noTitleMargin
        image={image}
      >
        <Text color="white">{position}</Text>
      </ImageBlock>

      <div className={classes.content}>
        <div className={classes.hide}>
          <Heading size="sm" color="white" noMargin>
            {name}
          </Heading>

          <Text color="white" spacing="sm">
            {position}
          </Text>
        </div>

        <Text size="sm" color="white">
          {children}
        </Text>
      </div>
    </div>
  );
}

TeamImage.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  position: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TeamImage;
