import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import teamImage from 'assets/images/pages/overons_onsteam/1.jpg';

import Title from 'blocks/Title';
import Content from 'blocks/Content';
import Grid from 'blocks/Grid';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import TeamImage from 'components/TeamImage';
import GridItem from 'components/GridItem';

const OverOnsTeam = ({ data }) => {
  const items = data.allTeam.nodes;

  return (
    <Layout>
      <Seo image={{ src: teamImage }} />

      <Title
        image={{ src: teamImage }}
        alt="Maak kennis met onze talenten"
        subtitle="Deze mensen zorgen voor jouw E-commerce succes!"
        title="Maak kennis met onze talenten"
      >
        Elkaar goed leren kennen voordat we samenwerken. Dat vinden we
        belangrijk. Een goede klik is een voorwaarde voor een goede
        samenwerking. Daarom stellen we ons heel erg graag voor!
      </Title>

      <Section>
        <Content>
          <Heading size="xl" as="h2">
            Over ons Team
          </Heading>

          <Text spacing="lg">
            Vormgevende kunstenaars, probleemoplossende ontwikkelaars en
            conversiegerichte online marketeers. Ons team bestaat uit
            gepassioneerde professionals die allemaal willen bijdragen aan een
            doel: het bouwen en optimaliseren van goede, mooie, moderne en
            converterende Magento webshops.
          </Text>

          <Button color="tertiary" to="/contact">
            Ontmoet onze talenten
          </Button>
        </Content>
      </Section>

      <Section color="secondary-pastel" position="left" shorten>
        <Content>
          <Heading size="xl" as="h2">
            Aangenaam kennis te maken
          </Heading>
        </Content>

        <Grid>
          {items.map((item) => (
            <TeamImage
              key={item._id}
              name={item.name}
              position={item.position}
              image={item.image}
            >
              {item.description}
            </TeamImage>
          ))}

          <GridItem>
            <Heading color="white" spacing="md">
              Jij?
            </Heading>

            <Text color="white" spacing="lg">
              Wij hebben altijd ruimte voor talent. Lijkt het je wat om bij
              Batao te werken? Bekijk dan onze openstaande vacatures, of stuur
              ons een open sollicitatie.
            </Text>

            <Button color="secondary" to="/vacatures">
              Bekijk Vacatures
            </Button>
          </GridItem>
        </Grid>
      </Section>

      <ContactCta />
    </Layout>
  );
};

OverOnsTeam.propTypes = {
  data: PropTypes.shape({
    allTeam: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          position: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query team {
    allTeam {
      nodes {
        _id
        name
        position
        description
        image {
          sharpImage: childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default OverOnsTeam;
