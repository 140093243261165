import React from 'react';
import PropTypes from 'prop-types';

import classes from './GridItem.scss';

function GridItem({ children }) {
  return <div className={classes.root}>{children}</div>;
}

GridItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GridItem;
